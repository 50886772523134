<template>
    <div>
        <form v-on:submit.prevent="resetPassword">
            <div class="text-2xl uppercase mb-4">
                Passwort zurücksetzen
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    Neues Passwort
                </label>
                <input-text
                    v-model="plainPassword"
                    class="w-full"
                    type="password"
                />
                <small class="p-invalid block mt-2" v-if="message">
                    {{ message }}
                </small>
                <small class="p-invalid block mt-2" v-for="message in errors.plainPassword" :key="message">
                    {{ message }}
                </small>
            </div>

            <div class="p-field mb-4">
                <label class="block text-600 mb-2">
                    Neues Passwort (Wied.)
                </label>
                <input-text
                    v-model="plainPasswordRepeat"
                    class="w-full"
                    type="password"
                />
            </div>

            <Button
                type="submit"
                label="Passwort zurücksetzen"
                class="w-full"
            />
        </form>
    </div>
</template>

<script>
    import InputText from 'primevue/inputtext';
    import Button from 'primevue/button';
    import util from '../../plugins/util';

    export default {
        components: {
            Button,
            InputText,
        },
        data() {
            return {
                plainPassword: '',
                plainPasswordRepeat: '',
                message: null,
                errors: {},
            }
        },
        methods: {
            resetPassword() {
                this.$store.commit('incrementLoadingIndex');
                this.message = null;
                this.errors = {};

                if(this.plainPasswordRepeat !== this.plainPassword) {
                    this.message = 'Die Passwörter stimmen nicht überein.';
                    this.$store.commit('decrementLoadingIndex');
                    return;
                }

                this.$http.post(
                    'actions/password-reset',
                    {
                        plainPassword: this.plainPassword,
                        passwordResetToken: this.$route.params.token,
                    }
                ).then(() => {
                    this.$store.commit('decrementLoadingIndex');
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Erfolgreich',
                        detail: 'Das Passwort wurde erfolgreich zurückgesetzt. Sie können sich nun mit den neuen Daten einloggen.',
                        life: 3000
                    });
                    this.$router.push('/login');
                }, (response) => {
                    if(response.status === 422) {
                        this.errors = util.mapConstraints(response.body);
                    } else {
                        this.message = 'Leider konnte das Passwort nicht gesetzt werden.';
                    }
                    this.$store.commit('decrementLoadingIndex');
                });
            }
        }
    }
</script>
